import React, { useState } from "react";
import companySignUp from "../landingPage/services";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer = () => {
  const [companyInfo, setCompanyInfo] = useState({});

  const getCompanySettings = () => {
    companySignUp
      .getCompanySettings()
      .then((response) => {
        setCompanyInfo(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getCompanySettings();
  }, []);

  return (
    <>
      <section id="footer" className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-6">
              <div className="single-footer-widget">
                <a href="/" className="logo">
                  <img
                    src="/assets/img/tappme-logo2.png"
                    alt="tappme-logo2"
                    height={90}
                    width={150}
                  />
                </a>
                <p className="mb-5 text-white">
                  TAPP ME is a product of MITAWAY AB. It is your all-in-one
                  solution designed to streamline registration processes,
                  enhance workflow visibility, and drive productivity across a
                  wide range of industries
                </p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.app.tappme"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/badge.png"
                    alt="tappme-android"
                    width={150}
                    height={90}
                  />
                </a>

                {"  "}
                <a
                  href="https://apps.apple.com/app/tapp-me/id6514303843"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/badge2.png"
                    alt="tappme-ios"
                    width={150}
                    height={44.5}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Quick Links</h3>
                <ul className="list">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/aboutus">About us</a>
                  </li>
                  <li>
                    <a href="/privacypolicy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/cookies">Cookies Policy</a>
                  </li>
                  <li>
                    <a href="/refundpolicy">Refund Policy</a>
                  </li>
                  <li>
                    <a href="/eula">Eula</a>
                  </li>
                  <li>
                    <a href="/termscondition">Terms and Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget">
                <h3>Get in Touch</h3>
                <ul className="info-list clearfix">
                  <li className="mb-4">
                    <i>
                      <img src="/assets/img/mail.svg" alt="tappme-logo2" />
                    </i>{" "}
                    <a
                      href={`mailto:${companyInfo.supportEmail}`}
                      className="text-white"
                    >
                      {companyInfo.supportEmail}
                    </a>
                  </li>
                  {/* <li className="mb-4">
                    <i>
                      <img src="/assets/img/phone.svg" alt="tappme-logo2" />
                    </i>{" "}
                    <a href="tel:+46 73 144 42 38" className="text-white">
                      +46 73 144 42 38
                    </a>
                  </li> */}
                  <li>
                    <i>
                      <img src="/assets/img/location.svg" alt="tappme-logo2" />
                    </i>{" "}
                    {companyInfo.address}
                  </li>
                  {/* <li>
                    <i>
                      <img src="/assets/img/location.svg" alt="tappme-logo2" />
                    </i>{" "}
                    Contact us
                  </li> */}
                  <div className="pricing-btn ps-3 mt-5">
                    <Link
                      style={{ cursor: "pointer" }}
                      className="default-btn3 px-5"
                      to={`/contactUs`}
                    >
                      Contact us
                    </Link>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="copy-right">
        <div className="container">
          <div className="copy-right-content">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <p className="text-white">
                  Copyright December 17, {companyInfo.regYear} © MITAWAY AB |
                  ALL RIGHTS RESERVED
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 single-footer-widget">
                <ul className="social-list">
                  <li>
                    <a
                      href="https://www.facebook.com/people/TAPP-ME/61555143403371"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/img/facebook.svg"
                        alt="tappme-facebook"
                        rel="noopener noreferrer"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/tapp.me2024/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/img/instagram.svg"
                        alt="tappme-instagram"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/showcase/tappme"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/img/linkedin.svg"
                        alt="tappme-linkedin"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@TAPPME/featured"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/assets/img/youtube.svg" alt="tappme-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";

function ServerSideTable(prop) {
  console.log("prop.getUsers", prop);
  // console.log("prop.getUsers", prop.count)
  const [pageSize, setPageSize] = useState(prop.pageSize);
  // const [count, setCount] = useState(prop.count);
  const count = prop.count;
  const [page, setPage] = useState(prop.page + 1);
  const [loading, setLoading] = useState();
  const [sortBy, setSortBy] = useState(prop.sortBy); // Track sortBy
  const [sortDir, setSortDir] = useState(prop.sortDir); // Track sortDir
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(prop.loading);
  }, [prop.loading]);

  const getRequestParams = (page, pageSize) => {
    const params = {};
    if (page) {
      console.log("if page", page, params);
      params.page = page - 1;
    }
    if (pageSize) {
      console.log("if pageSize", pageSize, params);

      params.size = pageSize;
    }
    return params;
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    prop.functionName({ page: value - 1, pageSize, sortBy, sortDir });
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event);
    // setPage(1);
  };

  const handleSortChange = (sortField, sortDirection) => {
    setSortBy(sortField);
    setSortDir(sortDirection);
    prop.functionName({
      page: page - 1,
      pageSize,
      sortBy: sortField,
      sortDir: sortDirection,
    });
  };

  const retrieveTutorials = () => {
    setLoading(true);
    const param = getRequestParams(page, pageSize);
    //  console.log("param", param)
    //  prop.functionName({ page: page, pageSize: pageSize })
    prop.functionName(param);
  };

  useEffect(() => {
    if (prop.data.length > 0) {
      setLoading(false);
    }
  }, [prop.data]);

  // eslint-disable-next-line
  useEffect(retrieveTutorials, [page, pageSize]);

  function CustomPagination() {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            padding: "5px 15px",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>
            {t("Page")}: {page} {t("of")} {count}
          </Typography>
          <MuiPagination
            className="my-3"
            color="secondary"
            count={count}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </Box>
      </>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer>
        <CustomPagination />
      </GridFooterContainer>
    );
  }

  return (
    <>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={prop.data}
          getRowId={(row) => row[`${prop.idname}`]}
          columns={prop.columns.map((col) => ({
            ...col,
            // sortable: true, // Enable sorting for columns
          }))}
          pagination
          slots={{
            pagination: CustomPagination,
            loadingOverlay: LinearProgress,
            footer: CustomFooter,
          }}
          onSortModelChange={(model) => {
            if (model.length) {
              const { field, sort } = model[0];
              handleSortChange(field, sort);
            }
          }}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[5]}
          localeText={{ noRowsLabel: t("No Data Found") }}
          disableColumnMenu
        />
      </Box>
    </>
  );
}

export default ServerSideTable;

import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ServerSideTable from "../../../common components/serverSideTable";
import BillingServices from "./services";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";

const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const BillingCompany = () => {
  const jwt = jwtDecode(localStorage.getItem("token"));

  const companyId = jwt.companyId;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [sortBy, setSortBy] = useState("createdAt"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  const formatDate = (value) => {
    const date = new Date(value);
    return date.toLocaleDateString();
  };
  const columns = [
    { field: "id", headerName: t("Id"), width: 100 },
    { field: "empId", headerName: t("Worker Id"), width: 150 },
    { field: "name", headerName: t("Name"), width: 150 },
    {
      field: "normalHours",
      headerName: t("Working Hours"),
      width: 150,
    },
    {
      field: "normalMinutes",
      headerName: t("Working Minutes"),
      width: 150,
    },
    {
      field: "overTimeHours",
      headerName: t("Overtime Hours"),
      width: 150,
    },
    {
      field: "overTimeMinutes",
      headerName: t("Overtime Minutes"),
      width: 150,
    },
    { field: "payrate", headerName: t("Pay Rate"), width: 120 },
    {
      field: "todayDate",
      headerName: t("Date"),
      width: 150,
    },
    { field: "amount", headerName: "Amount", width: 150 },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  const currentYear = new Date().getFullYear();
  const startYear = 2000; // Change this to whatever starting year you want
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const [workerList, setWorkerList] = useState([]);
  const getWorkerFilterList = () => {
    BillingServices.getWorkerFilterList(companyId)
      .then((response) => {
        setWorkerList(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getWorkerFilterList();
  }, []);

  const [workerId, setWorkerId] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  const onChangeWorkerId = (e) => {
    const workerId = e.target.value;
    setWorkerId(workerId);
  };

  const onChangeYear = (e) => {
    debugger;
    const year = e.target.value;
    setYear(year);
  };

  const onChangeMonth = (e) => {
    debugger;
    const month = e.target.value;
    setMonth(month);
  };

  const handleSearch = () => {
    getBilling();
  };

  const getBilling = async () => {
    debugger;
    try {
      const res = await BillingServices.getBillingData(
        workerId,
        year,
        month,
        page,
        pageSize,
        sortBy,
        sortDir
      );
      const { totalPages, totalItems, data } = res.data.data;
      if (res.status === 200) {
        setCount(totalPages);
        setTotalItems(totalItems);
        setData(data.Monthly);
        setLoading(false);
        setOpen(true);
        setSeverity("success");
        setMessage("Data fetch successfully.");
      }
    } catch (error) {
      console.log("UnExpected Error occur", error);
      if (error.response.status === 404) {
        setMessage("No data found");
        setSeverity("error");
        setOpen(true);
      }
      if (error.response.status === 500) {
        setMessage("Something went wrong!");
        setSeverity("error");
        setOpen(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getCompanies();
    window.scrollTo(0, 100);
  }, []);

  useEffect(() => {
    getBilling();
  }, [page, pageSize, sortBy, sortBy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="card radius-10 p-4 px-3">
        <div class="card-header bg-transparent border-0">
          <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
            <div className="col-12 col-lg-6 col-xl-6">
              <h5 className="mb-0 font-18 ">
                <strong> {t("Billing And Payment")}</strong>
              </h5>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 text-right"></div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
          <div className="col-12 col-lg-3 col-xl-3">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                {t("Select Worker")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={workerId}
                name="workerId"
                label={t("Select Worker")}
                onChange={onChangeWorkerId}
              >
                {workerList.map((item) => (
                  <MenuItem key={item.userId} value={item.userId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-12 col-lg-3 col-xl-3">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                {t("Month")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                name="month"
                label={t("Month")}
                onChange={onChangeMonth}
              >
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-12 col-lg-3 col-xl-3">
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="year-select-label">{t("Year")}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t("Year")}
                value={year}
                onChange={onChangeYear}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-12 col-lg-2 col-xl-2">
            <Button variant="contained" color="primary" onClick={handleSearch}>
              {t("Search")}
            </Button>
          </div>
        </div>
        <ServerSideTable
          pageSize={pageSize}
          page={page}
          sortBy={sortBy}
          sortDir={sortDir}
          count={count}
          functionName={getCompanies}
          data={data}
          columns={columns}
          loading={loading}
          idname="id"
        />
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default BillingCompany;

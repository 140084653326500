import ReCAPTCHA from "react-google-recaptcha";
import React, { useState } from "react";
import Footer from "./footer";
import Header from "./header";
import { Link } from "react-router-dom";
import "./contactus.css";
import { Button, Spinner } from "react-bootstrap";
import companySignUp from "../landingPage/services";
import Swal from "sweetalert2";
import { Alert, Snackbar, Stack } from "@mui/material";
import { useRef } from "react";
import { useEffect } from "react";

const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
const LIVE_SITE_KEY = "6LfB9CQqAAAAABzPGjjI9KPHjo1DuGRt_th2NKUa";
const DELAY = 1500;

const ContactUs = () => {
  const [callback, setCallback] = useState("not fired");
  const [value, setValue] = useState("[empty]");
  const [load, setLoad] = useState(false);
  const [expired, setExpired] = useState("false");
  const _reCaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const messsageDataSchema = {
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    message: "",
    service: "",
  };

  const [validated, setValidated] = useState(false);
  const [messsageData, setMesssageData] = useState(messsageDataSchema);
  const [showForm, setShowForm] = useState(false);
  const [contactValidated, setContactValidated] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoad(true);
    }, DELAY);
    console.log("didMount - reCaptcha Ref-", _reCaptchaRef);

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  const handleChange = (value) => {
    console.log("Captcha value:", value);
    setValue(value);
    if (value === null) setExpired("true");
  };

  const asyncScriptOnLoad = () => {
    setCallback("called!");
    console.log("scriptLoad - reCaptcha Ref-", _reCaptchaRef);
  };

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handleClosse = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setValidated(false);
  };

  function typeMessage(event) {
    const { name, value } = event.target;
    const phoneRegex = /^[0-9\b]+$/;
    // Check if the input value matches the phone number regex

    if (name === "phoneNumber") {
      if (phoneRegex.test(value) || value === "") {
        setMesssageData({ ...messsageData, [name]: value });
      }
    } else {
      setMesssageData({ ...messsageData, [name]: value });
    }

    console.log("typeMessage", messsageData);
  }

  const contactFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setSeverity("error");
      setMessage("Please fill out all required fields.");
      setOpen(true);
    } else {
      if (
        messsageData.name !== "" &&
        messsageData.surname !== "" &&
        messsageData.service !== "" &&
        messsageData.email !== "" &&
        messsageData.phoneNumber !== "" &&
        messsageData.message !== ""
      ) {
        sendMessage();
      } else {
        setSeverity("error");
        setMessage("Please fill out all required fields.");
        setOpen(true);
      }
    }

    setContactValidated(true);
  };

  function sendMessage() {
    debugger;
    setLoading(true);
    companySignUp
      .sendMessageApi(messsageData)
      .then((response) => {
        console.log(response);
        setMesssageData(messsageDataSchema);
        Swal.fire({
          // position: "top-end",
          icon: "success",
          title: "Message sent successfully!",
          showConfirmButton: false,
          timer: 2000,
        });
        setShowForm(false);
        setLoading(false);
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setSeverity("error");
        setOpen(true);
        setLoading(false);
        console.log("error", error.response.data.message);
      });
  }

  const [companyInfo, setCompanyInfo] = useState({});

  const getCompanySettings = () => {
    companySignUp
      .getCompanySettings()
      .then((response) => {
        setCompanyInfo(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getCompanySettings();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);
  return (
    <>
      <Header />
      <section className="page-title">
        <div className="container">
          <div className="title-outer">
            <h1 className="title">Contact Us</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="/" style={{ color: "white" }}>
                  Home
                </a>
                <span style={{ marginLeft: 20 }}>{">"}</span>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="contact-details">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <div className="sec-title">
                <span className="sub-title">Send us email</span>
                <h2>Feel free to write</h2>
              </div>

              <form
                id="contact_form"
                name="contact_form"
                noValidate
                validated={contactValidated}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input
                        className="form-control"
                        type="text"
                        value={messsageData.name}
                        placeholder="Enter first name*"
                        name="name"
                        maxLength="50"
                        onChange={(e) => typeMessage(e)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input
                        name="surname"
                        maxLength="50"
                        value={messsageData.surname}
                        onChange={(e) => typeMessage(e)}
                        className="form-control"
                        type="text"
                        placeholder="Enter surname name*"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input
                        name="email"
                        maxLength="50"
                        value={messsageData.email}
                        onChange={(e) => typeMessage(e)}
                        className="form-control required email"
                        type="email"
                        placeholder="Enter your email*"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input
                        minLength="10"
                        maxLength="15"
                        name="phoneNumber"
                        value={messsageData.phoneNumber}
                        onChange={(e) => typeMessage(e)}
                        className="form-control"
                        type="text"
                        placeholder="Enter your phone no*"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <div class="select mb-3">
                        <select
                          class="selectpicker form-control"
                          aria-label="Please select what service you want?"
                          data-live-search="true"
                          name="service"
                          value={messsageData.service}
                          onChange={(e) => typeMessage(e)}
                        >
                          <option value="">
                            Please select what service you want?
                          </option>
                          <option value="Product">Product</option>
                          <option value="Subscription">Subscription</option>
                          <option value="Finance">Finance</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <textarea
                    maxLength="1000"
                    name="message"
                    onChange={(e) => typeMessage(e)}
                    value={messsageData.message}
                    className="form-control required"
                    rows="7"
                    placeholder="Enter your message*"
                  ></textarea>
                </div>

                <div className="row">
                  <div className="mb-3">
                    {load && (
                      <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        ref={_reCaptchaRef}
                        sitekey={TEST_SITE_KEY}
                        onChange={handleChange}
                        asyncScriptOnLoad={asyncScriptOnLoad}
                      />
                    )}{" "}
                  </div>
                </div>

                <div className="mb-3">
                  <Button
                    color="light-primary"
                    className="me-2 btn rounded-pill"
                    block
                    size="lg"
                    onClick={contactFormSubmit}
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? <Spinner size="sm" /> : "Submit"}{" "}
                    {/* Show spinner if loading */}
                  </Button>
                </div>
              </form>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="contact-details__right">
                <div className="sec-title">
                  <span className="sub-title">Need any help?</span>
                  <h2>Get in touch with us</h2>
                  <div className="text">
                    We'd love to hear from you - please use the form to send us
                    your message or ideas.
                  </div>
                </div>
                <ul className="list-unstyled contact-details__info">
                  {/* <li>
                    <div className="icon bg-theme-color2">
                      <span className="bi bi-phone"></span>
                    </div>
                    <div className="text">
                      <h6>Have any question?</h6>
                      <a href="tel:980089850">
                        <span>Free</span> +1 9524357106
                      </a>
                    </div>
                  </li> */}
                  <li>
                    <div className="icon">
                      <span className="bi bi-envelope-fill"></span>
                    </div>
                    <div className="text">
                      <h6>Write email</h6>
                      <a href={`mailto:${companyInfo.supportEmail}`}>
                        {companyInfo.supportEmail}
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="bi bi-geo-alt-fill"></span>
                    </div>
                    <div className="text">
                      <h6>Visit anytime</h6>
                      <span>{companyInfo.address}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClosse}
        >
          <Alert
            onClose={handleClosse}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default ContactUs;

import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Switch } from "@mui/material";
import {
  truncateDescription,
  truncateName20,
} from "../../../../helper/StringSlicer";
import { jwtDecode } from "jwt-decode";
import { isValidName } from "../../../../helper/regex";
import ServerSideTable from "../../../common components/serverSideTable";
import SkillService from "./SkillService";
import { tree } from "d3";

const WorkerSkills = () => {
  const { t } = useTranslation();
  const jwt = jwtDecode(localStorage.getItem("token"));
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const dataJson = {
    name: "",
    description: "",
    status: true,
  };
  const [data, setData] = useState([]);
  const [skillData, setSkillData] = useState(dataJson);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [sortBy, setSortBy] = useState("id"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  const columns = [
    { field: "id", headerName: t("Skill Id"), width: 120 },
    { field: "name", headerName: t("Name"), width: 200 },
    {
      field: "description",
      headerName: t("Description"),
      width: 350,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("Status"),
      sortable: false,
      width: 120,
      renderCell: (param) => {
        function changeStatus(id, event) {
          debugger;
          console.log("status", !event);
          SkillService.changeSkillStatus(id, event).then((respons) => {
            setOpen(true);
            setMessage(respons.data.message);
            setSeverity("success");
            changeList();
          });
        }
        return (
          <Switch
            label="status"
            color="success"
            checked={param.row.status}
            onChange={(e) => changeStatus(param.row.id, e.target.checked)}
          />
        );
      },
    },
    {
      field: "view",
      headerName: t("Action"),
      sortable: false,
      width: 150,
      renderCell: (param) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditClick(param.row)}
          >
            {t("Edit")}
          </Button>
        );
      },
    },
  ];

  const handleEditClick = (skillData) => {
    setUpdateSkill({
      id: skillData.id,
      name: skillData.name,
      description: skillData.description,
      status: skillData.status,
    });
    setShowEdit(true);
  };

  function setValue(e) {
    debugger;
    const { name, value } = e.target;
    const trimmedValue = value.trimStart();

    if (name === "name" && !isValidName(value)) {
      return; // If the input does not match the regex, return early
    }
    setSkillData({
      ...skillData,
      [name]: trimmedValue,
    });
  }

  const [name, setName] = useState("");

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  function changeList() {
    setLoading(true);
    SkillService.workerskill(jwt.id, page, pageSize, name, sortBy, sortDir)
      .then((response) => {
        const { totalPages, totalElements, content } = response.data.data;
        setCount(totalPages);
        setTotalItems(totalElements);
        setData(content);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const handleSearch = () => {
    setPage(0);
    changeList();
  };

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const handleCloseEdit = () => setShowEdit(false);

  const handleCloses = () => {
    setShow(false);
    setSkillData(dataJson);
  };

  const handleShow = () => {
    setShow(true);
    // setupdateData([]);
    setValidated(false);
  };

  function workSkillSave() {
    if (
      skillData?.name?.trim() !== "" &&
      skillData?.description?.trim() !== ""
    ) {
      debugger;
      setLoading(true);
      SkillService.workerskillSave(skillData)
        .then((response) => {
          console.log(response);
          // workerskillGet();
          if (response.data.status == true) {
            setMessage(response.data.message);
            setSeverity("success");
            setSkillData(dataJson);
            setOpen(true);
            setShow(false);
            setLoading(false);
            changeList();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 406) {
            setMessage(error.response.data.message);
            setSeverity("error");
            setOpen(true);
          }
          if (error.response.status === 500) {
            setMessage("Server error");
            setSeverity("error");
            setOpen(true);
          }
          setLoading(false);
        });
    }
  }

  const [validated, setValidated] = useState(false);

  function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // Use trim() to remove both leading and trailing spaces
      const trimmedName = skillData.name.trim();
      const trimmedDescription = skillData.description.trim();

      if (trimmedName !== "" && trimmedDescription !== "") {
        skillData.name = trimmedName;
        skillData.description = trimmedDescription;
        workSkillSave();
      } else {
        setMessage("Fill all the required fields");
        setSeverity("error");
        setOpen(true);
      }
    }

    setValidated(true);
  }

  const initialStateData = {
    id: "",
    name: "",
    description: "",
    status: true,
  };

  const [updateSkill, setUpdateSkill] = useState(initialStateData);

  function setEditValue(e) {
    const { name, value } = e.target;

    if (name === "name" && !isValidName(value)) {
      return; // If the input does not match the regex, return early
    }
    setUpdateSkill({
      ...updateSkill,
      [name]: value,
    });
  }

  const handleSubmitEdit = (event) => {
    debugger;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (updateSkill.name.trim() !== "" && updateSkill.description !== "") {
        debugger;
        workSkillUpdate();
      } else {
        debugger;
        setMessage("Fill all the require fields");
        setSeverity("error");
        setOpen(true);
      }
    }

    setValidated(true);
  };

  function workSkillUpdate() {
    if (
      updateSkill.name.trim() !== "" &&
      updateSkill.description.trim() !== ""
    ) {
      debugger;
      setLoading(true);
      SkillService.workerSkillUpdate(updateSkill)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setMessage(response.data.message);
            setSeverity("success");
            // setData((prevData) => [...prevData, response.data.data]);
            setOpen(true);
            setShow(false);
            handleCloseEdit();
            setLoading(false);
            changeList();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 406) {
            setMessage(error.response.data.message);
            setSeverity("error");
            setOpen(true);
          }
          if (error.response.status === 500) {
            setMessage("Server error");
            setSeverity("error");
            setOpen(true);
          }
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(changeList, [page, pageSize, sortBy, sortDir]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="card radius-10 w-100">
        <div class="card-body px-4">
          <div class="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
            <div class="col-12 col-lg-6 col-xl-6">
              <h5 class="mb-0 font-18">{t("Skills")}</h5>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 text-right">
              <Button variant="outlined" type="button" onClick={handleShow}>
                {t("Add New Skill")}
              </Button>
            </div>
            <ServerSideTable
              pageSize={pageSize}
              page={page}
              sortBy={sortBy}
              sortDir={sortDir}
              count={count}
              functionName={getCompanies}
              data={data}
              columns={columns}
              loading={loading}
              idname="id"
            />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleCloses}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Add New Skill")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Skill Name")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder={t("Enter skill name")}
                    value={skillData.name}
                    maxLength={35}
                    name="name"
                    onChange={setValue}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter skill name
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-12 col-lg-12 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Skill Description")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    as="textarea"
                    placeholder={t("Enter skill description")}
                    value={skillData.description}
                    name="description"
                    maxLength={50}
                    onChange={setValue}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter description
                  </Form.Control.Feedback>
                </Form.Group>
                {/* {updateData.name}
                {updateData.description} */}
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="me-2"
            color="inherit"
            variant="contained"
            onClick={handleCloses}
          >
            {t("Close")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading} // Disable the button while loading
            startIcon={loading ? <CircularProgress size={20} /> : null} // Add a loader inside the button
          >
            {loading ? t("Saving...") : t("Save")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Edit Skill")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Skill Name")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder={t("Enter skill name")}
                    value={updateSkill.name}
                    maxLength={35}
                    name="name"
                    onChange={setEditValue}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter skill name
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-12 col-lg-12 mb-4">
                <Form.Group controlId="validationCustom01">
                  <Form.Label>{t("Skill Description")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    as="textarea"
                    placeholder={t("Enter skill description")}
                    value={updateSkill.description}
                    name="description"
                    maxLength={50}
                    onChange={setEditValue}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter description
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="me-2"
            color="inherit"
            variant="contained"
            onClick={handleCloseEdit}
          >
            {t("Close")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitEdit}
            disabled={loading} // Disable the button while loading
            startIcon={loading ? <CircularProgress size={20} /> : null} // Add a loader inside the button
          >
            {loading ? t("Updating...") : t("Update")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};
export default WorkerSkills;

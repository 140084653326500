import { useEffect, useRef, useState } from "react";
import myProfileApi from "./services";
import { jwtDecode } from "jwt-decode";
import WorkForceApi from "../workFroce/services";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

const CompanyProfile = () => {
  const jwt = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [data, setdata] = useState([]);
  const [formData, setFormData] = useState({
    imageUrl: "",
  });

  const dataJson = {
    id: jwt.companyId,
    phoneNo: "",
    ssn: "",
    registrationNo: "",
    taxAgencyNo: "",
  };

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    name: "",
    phoneNo: "",
    ssn: "",
  });
  const [datas, setDatas] = useState(dataJson);

  function getProfile() {
    myProfileApi.getMyProfile(jwt.id).then((response) => {
      setdata(response.data.data);
      setDatas({
        ...datas,
        phoneNo: response.data.data.company?.phoneNo,
        ssn: response.data.data.ssn,
        registrationNo: response.data.data.company?.registrationNo,
        taxAgencyNo: response.data.data.company?.taxAgencyNo,
      });
      setFormData({ ...formData, imageUrl: response.data.data.imageUrl });
    });
  }

  useEffect(() => {
    getProfile();
  }, []);

  const [workerdata, setWorkerdata] = useState([]);

  function getAssinedWorker() {
    WorkForceApi.assignedWorker(27).then((respons) => {
      const { workers } = respons.data;

      setWorkerdata(workers);
    });
  }
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const fileInputRef = useRef(null);

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      setMessage("Only jpg, jpeg, and png are allowed.");
      setSeverity("error");
      setOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("image", file);

    myProfileApi
      .uploadProfile(formData)
      .then((response) => {
        const data = response.data;
        setMessage(data.message);
        setSeverity("success");
        setOpen(true);
        getProfile(jwt.id);
        window.location.reload();
        // setImageUrl(file);
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setSeverity("error");
        setOpen(true);
      });
  };

  useEffect(() => {
    getAssinedWorker();
  }, []);

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setValidated(false);
  };

  const handleShow = () => setShow(true);

  const handleClosse = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const setValue = (e) => {
    const { name, value } = e.target;
    const phoneRegex = /^[0-9\b]+$/;
    const spaceRegex = /\s{2,}/g; // Regex to trim consecutive spaces

    let sanitizedValue = value.replace(spaceRegex, " ").trim(); // Replace consecutive spaces with single space and trim

    if (name === "phoneNo" || name === "ssn") {
      if (sanitizedValue === "" || phoneRegex.test(sanitizedValue)) {
        setDatas({ ...datas, [name]: sanitizedValue });
      }
    } else {
      setDatas({ ...datas, [name]: sanitizedValue });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      // If form validation fails, display error messages for invalid fields
      event.stopPropagation();
      setValidated(true); // Enable form validation to display feedback to the user
    } else {
      // Form validation passed, check if required fields are filled
      if (
        !datas.registrationNo ||
        !datas.phoneNo ||
        !datas.taxAgencyNo ||
        !datas.ssn
      ) {
        // If any required field is empty, display error message and prevent form submission
        setMessage("Please fill in all required fields.");
        setSeverity("error");
        setOpen(true);
      } else {
        // All required fields are filled, proceed with form submission
        updateProfile();
      }
    }
    setValidated(true);
  };

  function updateProfile() {
    debugger;
    // Check if the phone number is a valid number
    if (isNaN(datas.phoneNo)) {
      setMessage("Enter a valid phone number");
      setSeverity("error");
      setOpen(true);
    } else {
      // All required fields are filled and phone number is valid, proceed with updating profile
      myProfileApi
        .updateProfile(datas)
        .then((response) => {
          const data = response.data;
          setMessage(data.message);
          setSeverity("success");
          setOpen(true);
          getProfile();
          handleClose();
        })
        .catch((error) => {
          setMessage("An error occurred while updating profile.");
          setSeverity("error");
          setOpen(true);
          console.error(error);
        });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="p-3 pt-0 bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-6 col-md-2 col-lg-2">
              <h5 className="mb-0 font-18">{t("My_Profile")}</h5>
            </div>

            <div className="col-6 col-md-10 col-lg-10 text-right">
              <button
                className="btn btn-outline-secondary btn-blue font-14 toggle"
                type="button"
                onClick={handleShow}
              >
                <i className="fadeIn animated bx bx-pencil"></i>{" "}
                {t("Edit_Profile")}
              </button>
            </div>
          </div>
        </div>
        <div className="card-body profile">
          {/* <div className="row g-3 align-items-center mt-1">
                  <div className="col-12 col-md-2 col-lg-2">
                     <img className="Rectangle32 mb-2" src={data?.imageUrl} onerror="this.src='/assets/images/profile.png';" /> <br />

                  </div>

               </div> */}
          <Row className=" mt-1">
            <Col xs={12} md={3} lg={3}>
              <div className="profilePicture">
                <img
                  className="Rectangle32 mb-2"
                  src={formData.imageUrl}
                  alt="Loding ..."
                  onError={(e) => {
                    e.target.onerror = null; // To prevent infinite loops in case the default image also fails
                    e.target.src =
                      "https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png"; // Set the URL of the default image here
                  }}
                />
              </div>
              <br />
              <a
                className="mt-2 text-primary cursor-pointer"
                onClick={() => fileInputRef.current.click()}
              >
                {t("Change_Profile_Picture")}
              </a>
            </Col>
            <input
              type="file"
              ref={fileInputRef}
              accept="image/jpeg, image/png, image/jpg"
              style={{ display: "none" }}
              onChange={handleLogoChange}
            />
          </Row>
          {data ? (
            <>
              <div class="row mt-5">
                <div class="col-12 col-md-6 col-lg-6 mb-4">
                  <div class="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span class="font-14 text-dark"> {t("Company_name")}</span>
                    <span class="font-14 text-gray">
                      {" "}
                      {data.company?.companyName}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 mb-4">
                  <div class="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span class="font-14 text-dark"> {t("Phone No")}</span>
                    <span class="font-14 text-gray">
                      {" "}
                      {data.company?.phoneNo}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6 col-lg-6 mb-4">
                  <div class="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span class="font-14 text-dark">{t("Email")}</span>
                    <span class="font-14 text-gray text-right">
                      {data.company?.email}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 mb-4">
                  <div class="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span class="font-14 text-dark">{t("Personal/SSN")} </span>
                    <span class="font-14 text-gray text-right">
                      {" "}
                      {data?.ssn}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6 col-lg-6 mb-4">
                  <div class="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span class="font-14 text-dark">
                      {" "}
                      {t("Corporate_Reg no.")}
                    </span>
                    <span class="font-14 text-gray text-right">
                      {" "}
                      {data.company?.registrationNo}{" "}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 mb-4">
                  <div class="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                    <span class="font-14 text-dark">
                      {" "}
                      {t("Tax_Agency_no.")}
                    </span>
                    <span class="font-14 text-gray text-right">
                      {" "}
                      {data.company?.taxAgencyNo}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Edit Profile")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form-body pt-2" noValidate validated={validated}>
            <div className="row g-3">
              <div className="col-12">
                <Form.Group
                  controlId="validationCustom01"
                  className="ms-auto position-relative"
                >
                  <Form.Label>{t("Phone No")}</Form.Label>
                  <Form.Control
                    className="form-control radius-4 height-50"
                    required
                    type="text"
                    placeholder="Phone Number"
                    name="phoneNo"
                    value={datas.phoneNo}
                    onChange={setValue}
                    maxLength={15}
                    minLength={10}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ position: "absolute" }}
                  >
                    {errors.phoneNo}
                    {/* Enter phone number */}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group
                  controlId="validationCustom01"
                  className="ms-auto position-relative"
                >
                  <Form.Label>
                    {t("Personal/Social_security_number")}
                  </Form.Label>
                  <Form.Control
                    className="form-control radius-4 height-50"
                    required
                    type="text"
                    placeholder="Social security number"
                    name="ssn"
                    value={datas.ssn}
                    onChange={setValue}
                    maxLength="15"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ position: "absolute" }}
                  >
                    {errors.ssn}
                    {/* Enter ssn number */}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group
                  controlId="validationCustom01"
                  className="ms-auto position-relative"
                >
                  <Form.Label>{t("Corporate_Reg no.")}</Form.Label>
                  <Form.Control
                    className="form-control radius-4 height-50"
                    required
                    type="text"
                    placeholder="Corporate Reg no."
                    name="registrationNo"
                    value={datas.registrationNo}
                    onChange={setValue}
                    maxLength="15"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ position: "absolute" }}
                  >
                    {errors.registrationNo}
                    {/* Enter registration number */}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group
                  controlId="validationCustom01"
                  className="ms-auto position-relative"
                >
                  <Form.Label>{t("Tax_Agency_no")}</Form.Label>
                  <Form.Control
                    className="form-control radius-4  height-50"
                    required
                    type="text"
                    placeholder="Tax Agency no."
                    name="taxAgencyNo"
                    value={datas.taxAgencyNo}
                    onChange={setValue}
                    maxLength="15"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ position: "absolute" }}
                  >
                    {errors.taxAgencyNo}
                    {/* Enter texagency number */}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {t("Update")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleCloses}
        >
          <Alert
            onClose={handleCloses}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default CompanyProfile;
